'use strict'

import Vue from 'vue'
import dotProp from '../util/dot-prop'
import dayjs from 'dayjs'
import { FilterFactory } from './FilterFactory'
import { isObject, isString } from '../util/helpers'

export class SearchList {
  constructor (id, data, filters, sortingOptions, metadataService) {
    this.id = id
    this.allItems = data
    this.metadataService = metadataService

    this.debounceId = undefined;

    this.searchResults = data
    this.searchString = ''

    // create filters
    this.filtersSource = []
    this.activeFilters = {}
    filters.forEach(filter => this.activeFilters[filter.field] = [])

    for (const filter of filters) {
      this.filtersSource.push(filter)
    }

    // create sortable
    this.sortingOptions = sortingOptions
    this.activeSort = sortingOptions.length > 0 ? sortingOptions[0] : {}

    // Apply all filters and the sort option
    this._filter()
    this._updateSearchResults()
    this._updateFilters()
  }

   _updateFilters() {
    this.filtersSource = FilterFactory.setValuesFromItems(this.filtersSource, this.allItems, this.metadataService)

    this.filters = []
    for (const filterSource of this.filtersSource) {
      if (filterSource.values.length > 0) {
        this.filters.push(filterSource)
      }
    }
  }

  _filter () {
    this._filteredData = this.allItems.filter(row => {
      for (const activeFilterKey in this.activeFilters) {

        const selectedFilterValues = this.activeFilters[activeFilterKey]
        if (selectedFilterValues.length === 0) {
          continue
        }

        let values = dotProp.get(row, activeFilterKey, []);
        if (isString(values)) {
          values = [values];
        }

        const rowValues = values.map(value => {
          if (isObject(value) && value.hasOwnProperty('identifier')) {
            return value.identifier;
          }

          return value;
        })

        const matchesAnyValue = selectedFilterValues.filter(filterValue => rowValues.includes(filterValue.value)).length > 0
        if (!matchesAnyValue) {
          return false
        }
      }
      return true
    })
  }

  _sort (a, b) {
    const applyReverse = (value) => {
      if (value === 0) {
        return value
      }
      return this.activeSort.reverse ? -value : value
    }

    const aValue = dotProp.get(a, this.activeSort.field)
    const bValue = dotProp.get(b, this.activeSort.field)

    // Date comparison
    if (this.activeSort.type === 'date') {
      const dateA = dayjs(aValue)
      const dateB = dayjs(bValue)

      if (dateA.isSame(dateB)) {
        return applyReverse(this._compareTitles(a, b))
      }
      return applyReverse(dateA.isBefore(dateB) ? -1 : 1)
    }

    // Case insensitive comparison
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      const result = aValue.localeCompare(bValue, 'nl')
      if (result !== 0) {
        return applyReverse(result)
      }
      return applyReverse(this._compareTitles(a, b))
    }

    // Default comparison
    if (aValue < bValue) {
      return applyReverse(-1)
    }
    if (aValue > bValue) {
      return applyReverse(1)
    }

    return applyReverse(this._compareTitles(a, b))
  }

  _compareTitles (a, b) {
    return a.title.localeCompare(b.title, 'nl')
  }

  _updateSearchResults () {
    if (this.searchString !== '') {
      this.searchResults = this._filteredData.filter(element => element.title.toLowerCase().includes(this.searchString))
    } else {
      this.searchResults = this._filteredData.slice(0)
    }
    if (typeof this.activeSort === 'object' && this.activeSort.field != null) {
      this.searchResults.sort(this._sort.bind(this))
    }
  }

  hasData () {
    return this.allItems.length > 0
  }

  getData () {
    return this.allItems
  }

  get totalRows () {
    return this.allItems.length
  }

  hasSearchString () {
    return this.searchString !== ''
  }

  filterOn (field, value) {
    if (value.value === '') {
      return
    }

    const activeFilter = this.activeFilters[field]
    activeFilter.push(value)

    Vue.set(this.activeFilters, field, activeFilter)
    this._filter()
    this._updateSearchResults()
  }


  filterIsActiveAndHasValue (field, value) {
    return this.activeFilters[field].some(activeFilterValue => {
      return activeFilterValue.value === value
    })
  }

  removeFilterValue (field, value) {
    if (this.filterIsActiveAndHasValue(field, value)) {
      this.activeFilters[field].splice(this.activeFilters[field].findIndex(activeFilter => activeFilter.value === value), 1)
    }

    this._filter()
    this._updateSearchResults()
  }

  clearFilter (field) {
    this.activeFilters[field] = []
    this._filter()
    this._updateSearchResults()
  }

  clearAllFilters () {
    for (const activeFilterKey in this.activeFilters) {
      this.activeFilters[activeFilterKey] = []
    }
    this._filter()
    this._updateSearchResults()
  }

  sortOn (option) {
    this.activeSort = option
    this._updateSearchResults()
  }

  search (searchString, debounce) {
    if (this.debounceId) {
      clearTimeout(this.debounceId);
      this.debounceId = undefined;
    }

    if (debounce === false) {
      return this.#performSearch(searchString);
    }

    this.debounceId = setTimeout(() => this.#performSearch(searchString), 300);
  }

  #performSearch (searchString) {
    clearTimeout(this.searchEventTimeout);

    if (isString(searchString)) {
      searchString = searchString.trim();
      if (searchString !== '') {
        // Set a new timeout to send the gtag event after half a second
        this.searchEventTimeout = setTimeout(() => {
          gtag('event', 'search', { search_term: searchString });
        }, 500);
      }
    }

    if (this.searchString !== searchString) {
      this.searchString = searchString.toLowerCase();
      this._updateSearchResults();
    }
  }

  reset () {
    this.searchString = ''
    this.activeFilters = {}
    this.activeSort = {}

    this._filter()
    this._updateSearchResults()
  }

  find (findCallback) {
    return this.allItems.find(findCallback)
  }

  findByContentIdentifier (contentIdentifier) {
    return this.allItems.find(element => element.contentIdentifier === contentIdentifier)
  }
}
