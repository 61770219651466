<template>
  <div class="arrangement-search-results"
       :class="{ 'expanded': !detailStore.collapsed, 'collapsed': detailStore.collapsed }">
    <div class="arrangement-search-results__header" :class="{ 'flexbox': !detailStore.collapsed }">
      <div
        class="arrangement-search-results__header-inner"
        :class="{ 'flex-align-items--center': !detailStore.collapsed }"
      >
        <span class="arrangement-column__header">
          <span style="text-wrap: none">{{ $t('detail.search.results_header') }}</span>
          <span class="arrangement-search-results__label" data-test="number-of-search-results">
            {{ resultCount < total ? $t('list.number_of_search_results', { current: resultCount, total }) : $t('list.total_search_results', { total }) }}
          </span>
        </span>
      </div>

      <div
        class="flexbox flex-align-items--center ml-auto arrangement-search-results__search-container"
        v-if="!detailStore.collapsed && !store.loadingArrangementItems"
      >
        <a-text-input
          part="search"
          icon="search"
          color="grey"
          data-test="search-input"
          :placeholder="$t('generic.input.search.placeholder')"
          @search="handleSearchInput"
          @focus="detailStore.collapsed = false"
          @input="handleSearchInput"
          @clear="clearSearchInput"
          clearable
        ></a-text-input>
      </div>



      <div ref="wc"/>

      <a-select
        :style="`inline-size: ${sortSelectorWidth}px`"
        v-if="!detailStore.collapsed && detailStore.sortingOptions.length > 1"
        class="mx-4 sorting-select"
        :placeholder="$t('generic.dropdowns.sorting.placeholder')"
        data-test="assignment-items-sort-dropdown"
        :disabled="store.arrangementItems.hasSearchString()"
      >
        <a-select-option
          v-for="(sortingOption, index) in detailStore.sortingOptions"
          :key="'select_option_' + index"
          :value="sortingOption.field"
          :data-test="createDataTest(sortingOption)"
          :selected="selectedSortingOption.field === sortingOption.field"
          @click="handleSelect(sortingOption)"
        >{{ sortingOption.title }}</a-select-option>
      </a-select>
    </div>

    <available-learning-items v-if="!detailStore.collapsed"/>
  </div>
</template>

<script>
  import AvailableLearningItems from '../AvailableLearningItems'
  import InlineSvg from '../../../../components/InlineSvg'
  import { detailStore, store } from '../../../../store'

  export default {
    components: {
      AvailableLearningItems,
      InlineSvg
    },
    data () {
      return {
        store,
        detailStore,
        sortSelectorWidth: 200
      }
    },
    methods: {
      handleSearchInput (event) {
        store.arrangementItems.search(event.target.value)
      },
      clearSearchInput (event) {
        store.arrangementItems.search(event.target.value, false)
      },
      createDataTest(option) {
        return 'dropdown-option-' + option.field + '-reverse-' + (true === option.reverse ? 'true' : 'false')
      },
      handleSelect (option) {
        store.arrangementItems.sortOn(option)
      },
      calculateSelectWidth() {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = '16px proxima-nova';

        this.sortSelectorWidth = detailStore.sortingOptions.reduce((carry, option) => {
          const w = context.measureText(option.title).width;
          return Math.max(carry, Math.ceil(w) + 64);
        }, 0);

        canvas.remove();
      },
    },
    computed: {
      arrangement () {
        return store.currentArrangement
      },

      availableItems () {
        if (this.arrangement === undefined) {
          return []
        }
        return store.arrangementItems.searchResults.filter(
          item => !this.arrangement.items.find(
            arrangementItem => arrangementItem.contentIdentifier === item.contentIdentifier
          )
        )
      },

      unfilteredAvailableItems () {
        if (this.arrangement === undefined) {
          return []
        }
        return store.arrangementItems.allItems.filter(
          item => !this.arrangement.items.find(
            arrangementItem => arrangementItem.contentIdentifier === item.contentIdentifier
          )
        )
      },

      resultCount () {
        return this.availableItems.length
      },
      total () {
        return this.unfilteredAvailableItems.length
      },
      selectedSortingOption () {
        return store.arrangementItems.activeSort;
      }
    },
    watch: {
      'detailStore.sortingOptions'() {
        this.calculateSelectWidth();
      }
    },
  }
</script>
