import i18n from './common/i18n'

import dotProp from './util/dot-prop'
import SortService from './util/SortService'
import {Arrangements} from './store.arrangements'
import {ArrangementItems} from './store.arrangement-items'
import {DetailStore} from './store.details'
import ArrangementStore from './store.arrangement'
import ArrangementService from './services/ArrangementService'
import RoleService from './services/RoleService'
import MethodService from './services/MethodService'

import Groups from './services/Groups'
import {contentIdentifierNotEqual} from './filters/contentIdentifier'
import {EventBus} from "./common/EventBus";

// initial value
const arrangements = Arrangements.createSearchList('arrangements', [], [])

export const detailStore = DetailStore

export const store = {
  arrangementService: null,
  roleService: null,
  methodService: null,
  arrangements: arrangements,
  arrangementItems: {},
  loadingGroups: false,
  loadingArrangement: false,
  loadingArrangements: false,
  loadingArrangementItems: false,
  currentArrangement: {},
  groups: null,
  methods: null,
  filterFields: [],
  itemMetadataKeys: [],
  arrangeParagraphEnabled: false,
  hasAdminLicense: false,
  urlParams: new URLSearchParams(window.location.search),
  inAdminMode: false,
  hasArrangementItems: false,

  // for arranging learning item children
  learningItemChildren: [],

  state: {
    backToRoute: {
      name: 'arrangements-index'
    },
    backToTitle: i18n.t('generic.my_arrangements'),
    changesPending: false
  },

  get loading() {
    return this.loadingGroups || this.loadingArrangement || this.loadingArrangements || this.loadingArrangementItems;
  },

  init: function (method, theme, filterFields, groups, itemMetadataKeys, sortingOptions, hasAdminLicense, arrangeParagraphEnabled, authenticatedUserReference, unleashClient, inAdminMode = false) {
    this.method = method
    this.theme = theme
    this.unleashClient = unleashClient

    this.arrangementService = new ArrangementService(method.slug, inAdminMode)
    this.roleService = new RoleService()
    this.methodService = new MethodService()
    this.sortService = new SortService()
    this.filterFields = this.sortService.sortByMetadataIdentifier(filterFields, 'key')
    this.itemMetadataKeys = this.sortService.sortByMetadataIdentifier(itemMetadataKeys, null)
    this.contentIdentifiers = []
    this.groups = new Groups(groups)
    this.methods = []
    this.arrangeParagraphEnabled = arrangeParagraphEnabled
    this.authenticatedUserReference = authenticatedUserReference
    this.hasAdminLicense = hasAdminLicense

    this.inAdminMode = inAdminMode
    this.clearCurrentArrangement()
    this.sortingOptions = this.sortService.sortByMetadataIdentifier(sortingOptions, 'field')
  },

  clearCurrentArrangement() {
    this.arrangementItems = ArrangementItems.createSearchList([], this.filterFields, null)
    this.hasArrangementItems = false
    this.currentArrangementStore = null
  },

  createCurrentArrangementStore() {
    this.currentArrangementStore = new ArrangementStore(this, this.currentArrangement, this.arrangeParagraphEnabled)

    return this.currentArrangementStore
  },

  getCurrentArrangementStore() {
    return this.currentArrangementStore
  },

  removeFromArrangement(item) {
    this.currentArrangement.items = contentIdentifierNotEqual(this.currentArrangement.items, item.contentIdentifier)
    delete item.children
    this.setChangesPending()
  },

  addToArrangement(item) {
    this.currentArrangement.items.push(item)
    this.setChangesPending()
  },

  setChangesPending() {
    this.state.changesPending = this.currentArrangementHasChanges()
  },

  currentArrangementHasChanges() {
    return this.currentArrangementHash !== this.getCurrentArrangementHash()
  },

  clearChangesPending() {
    this.state.changesPending = false
    this.currentArrangementHash = this.getCurrentArrangementHash()
  },

  getRouteBackLink(currentRoute) {
    const backLinks = {
      'methods-list': 'arrangements-index',
      'new-arrangement-start': 'arrangements-index',
      'new-arrangement': 'new-arrangement-start',
      'new-arrangement-from-template': 'new-arrangement-start',
      'arrangement-detail': 'arrangements-index'
    }
    return backLinks[currentRoute] ? backLinks[currentRoute] : ''
  },

  getEmptyArrangement() {
    return {
      code: '',
      revision: Date.now(),
      title: '',
      methods: [],
      learningLevels: [],
      subjects: [],
      items: [],
      schoolYears: [],
      audienceIsUnrestricted: false,
      createdAt: new Date(),
      updatedAt: new Date()
    }
  },

  showSaveWarning(arrangement) {
    return this.isPredefined(arrangement) ? false : !this.isArrangementOfAuthenticatedUser(arrangement)
  },

  showDeleteWarning(arrangement) {
    return this.isPredefined(arrangement) ? false : !this.isArrangementOfAuthenticatedUser(arrangement)
  },

  showConnectWarning(arrangement) {
    return this.isPredefined(arrangement) ? false : !this.isArrangementOfAuthenticatedUser(arrangement)
  },

  isPredefined(arrangement) {
    return 'predefined' === arrangement.type
  },

  isArrangementOfAuthenticatedUser(arrangement) {
    return this.authenticatedUserReference === arrangement.creator
  },

  isArrangementLastEditedByAuthenticatedUser(arrangement) {
    if (arrangement.lastModifiedBy) {
      return this.authenticatedUserReference === arrangement.lastModifiedBy
    }
    return this.isArrangementOfAuthenticatedUser(arrangement)
  },

  async switchRole() {
    this.inAdminMode = !this.inAdminMode;
    await this.roleService.switchRole()
    EventBus.$emit('toggleAdminMode', this.inAdminMode); // Emit the event with the flag value
  },

  async loadTemplateArrangements() {
    this.loadingArrangements = true;
    const arrangements = await this.arrangementService.loadTemplateArrangements()
    this.arrangements = Arrangements.createSearchList('template', arrangements, [
      {
        field: 'title',
        title: 'Titel A-Z'
      },
      {
        field: 'title',
        title: 'Titel Z-A',
        reverse: true
      }
    ])
    this.loadingArrangements = false;
  },

  async loadMyArrangements() {
    this.loadingArrangements = true;
    const arrangements = await this.arrangementService.loadMyArrangements(store.inAdminMode)
    this.arrangements = Arrangements.createSearchList('my-arrangements', arrangements, [
      {
        field: 'title',
        title: 'Titel A-Z'
      },
      {
        field: 'title',
        title: 'Titel Z-A',
        reverse: true
      },
      {
        field: 'updatedAt',
        title: 'Datum (oplopend)',
        type: 'date'
      },
      {
        field: 'updatedAt',
        title: 'Datum (aflopend)',
        type: 'date',
        reverse: true
      }
    ])
    this.loadingArrangements = false;
  },

  async loadMethods() {
    this.methods = await this.methodService.loadMethods()
  },

  async loadArrangement(arrangementCode) {
    this.loadingArrangement = true;
    this.currentArrangement = await this.arrangementService.loadArrangement(arrangementCode)
    this.currentArrangement.items = ArrangementItems.withItemDisplayMetadata(
      await this.arrangementService.getContent(arrangementCode),
      this.itemMetadataKeys
    )
    this.clearChangesPending()
    this.loadingArrangement = false;
  },

  async createArrangement(arrangementData) {
    return await this.arrangementService.createArrangement(arrangementData, this.inAdminMode)
  },

  async saveArrangementContent(arrangement) {
    await this.arrangementService.saveArrangementContent(arrangement)
    this.clearChangesPending()
  },

  async loadLearningItems() {
    this.loadingArrangementItems = true
    this.arrangementItems = await this.arrangementService.loadLearningItems(
      this.filterFields,
      this.itemMetadataKeys
    )

    const items = this.arrangementItems.getData()
    this.setDetailStoreSortingOptions(items)
    if (this.currentArrangementStore !== null) {
      this.currentArrangementStore.setAvailableItems(items)
      this.hasArrangementItems = items.length > 0
    }
    this.loadingArrangementItems = false
  },

  setDetailStoreSortingOptions(items) {
    DetailStore.sortingOptions = this.sortingOptions.filter(
      option => null === option.field || this.hasMetadata(items, option.field)
    );
  },

  hasMetadata(items, key) {
    for (const item of items) {
      const value = dotProp.get(item, key, [])

      if (Array.isArray(value) && value.length > 0) {
        return true;
      }

      if ((typeof value === 'string' || value instanceof String) && '' !== value) {
        return true;
      }
    }

    return false;
  },

  async loadLearningItemChildren(forLearningItem) {
    // only the original learning item contains the streamCode from the arrangement it came from
    const originalLearningItem = this.arrangementItems.findByContentIdentifier(forLearningItem.contentIdentifier)
    if (originalLearningItem === undefined) {
      this.learningItemChildren = []
      return
    }
    this.loadingArrangementItems = true;
    this.learningItemChildren = await this.arrangementService.loadLearningItemChildren(originalLearningItem, this.filterFields, this.itemMetadataKeys)
    this.loadingArrangementItems = false;
  },

  async updateArrangement(arrangementCode, data) {
    await this.arrangementService.updateArrangement(arrangementCode, data)
  },

  async createArrangementFromArrangement(arrangementCode, newData) {
    return await this.arrangementService.createArrangementFromArrangement(arrangementCode, newData, store.inAdminMode)
  },

  async loadGroups() {
    if (this.groups === null) {
      this.loadingGroups = true;
      this.groups = new Groups(await this.arrangementService.getGroups())
      this.loadingGroups = false;
    }
  },

  getCurrentArrangementHash() {
    return JSON.stringify(this.currentArrangement)
  }
}
