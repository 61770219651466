<template>
  <div class="arrangement-search-controls pt-4">
    <filter-accordion/>
  </div>
</template>

<script>
  import FilterAccordion from './Accordion'
  import SelectedFilters from './SelectedFilters'
  import { store, detailStore } from '../../../../store'

  export default {
    components: {
      FilterAccordion,
      SelectedFilters
    },
    data () {
      return {
        store,
        detailStore
      }
    }
  }
</script>
