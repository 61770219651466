const metadataOrder = [
  'learningMetadata.learningLevels',
  'learningMetadata.referenceLevel',
  'learningMetadata.cefrQualifications',
  'streamMetadata.schoolYears',
  'smartMetadata.mboQualifications',
  'smartMetadata.mboProfile',
  'smartMetadata.mboCourseStructure',
  'smartMetadata.mboWorkProcesses',
  'smartMetadata.mboSubjectArea',
  'smartMetadata.mboTouchTech',
  'smartMetadata.mboTechnical',
  'smartMetadata.learningGoals',
  'smartMetadata.mboDoorNederlands',
  'smartMetadata.mboDoorEngels',
  'smartMetadata.rhvClassifications',
  'learningMetadata.workingMethod',
  'smartMetadata.musicStyles',
  'smartMetadata.musicDifficulty',
];

const getSortValue = (item, key) => {
  const identifier = getSortIdentifier(item, key);
  const index = metadataOrder.indexOf(identifier);
  
  if (index < 0) {
    return null === identifier ? -1 : 99999;
  }

  return index;
};

const getSortIdentifier = (item, key) => {
  const value = null === key ? item : item[key];

  return null === value ? value : value.replace('mergedMetadata.', '');
};

class SortService {
  sortByMetadataIdentifier(data, key) {
    return data.sort((a, b) => {
      return getSortValue(a, key) > getSortValue(b, key) ? 1 : -1;
    });
  }
}

export default SortService
